<template>
  <div class="copy-right border-top">
    <p v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'Yes' ">      
      <span>Powered by <u><a target="_blank" class="primary-link" href="https://deonde.co/">DEONDE</a></u></span>
    </p>
    <cookie-law theme="dark-lime">
      <div slot="message">This {{$auth.getAppName()}} uses cookies to provide you with a great user experience. Please accept cookies for excellent optimal performance</div>
    </cookie-law>
  </div>    
</template>

<script>
import CookieLaw  from 'vue-cookie-law';
export default {
  name:'footerpage',
  components: { CookieLaw }
}
</script>